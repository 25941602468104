import { StackProps, HStack, Button, Text, Divider, useMediaQuery } from "@chakra-ui/react";
import { useLanguage } from "../lib/LanguageContext";

export const LanguageToggle = (props: StackProps) => {
  const { lang, setLang } = useLanguage();
  const [isMobile] = useMediaQuery('(max-width: 430px)');

  return (
    <HStack {...props} height={isMobile ? '40px' : '50px'}>
      <Button variant="ghost" onClick={() => setLang('en')}>
        <Text fontSize={isMobile ? 'sm' : 'md'} color={lang === 'en' ? 'white' : 'lightgray'} cursor="pointer">EN</Text>
      </Button>
      <Divider orientation="vertical" colorScheme="whiteAlpha" />
      <Button variant="ghost" onClick={() => setLang('fr')} >
        <Text fontSize={isMobile ? 'sm' : 'md'} color={lang === 'fr' ? 'white' : 'lightgray'} cursor="pointer">FR</Text>
      </Button>
    </HStack>
  );
}
