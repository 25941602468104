import { Button, Center, VStack, HStack, Image, Text, Box, Badge } from "@chakra-ui/react";
import React from "react";
import { Reward } from "../store/RewardsStore";
import { LockIcon } from "@chakra-ui/icons";
import { useLanguage } from "../lib/LanguageContext";

type RewardItemProps = {
  reward: Reward;
  hasEnoughPoints: boolean;
  onClaim: () => void;
  spendable: number;
}

export const RewardItem: React.FC<RewardItemProps> = ({ reward, hasEnoughPoints, onClaim, spendable }) => {
  const { translate } = useLanguage();

  return (
    <VStack
      maxW="500px"
      minW="300px"
      borderRadius={8}
      overflow="hidden"
      m="0 1em"
      justifyContent="space-between"
      backgroundColor="white"
    >
        <Box position="relative">
          <Image opacity={hasEnoughPoints ? 1 : 0.5} src={reward.image.large} />
          {!hasEnoughPoints && 
            <Text position="absolute" top="0" right="0" left="0" maxH="1.5em" textAlign="center" bg="brand.500">EARN {reward.points - spendable} MORE POINTS</Text>
          }
        </Box>
        <HStack px={8} placeContent="start" gap="0.5em">
          <Text textAlign="start" fontSize="16px" textOverflow="ellipsis" color="brand.500" fontWeight="bold" letterSpacing="-1px">{reward.title.toUpperCase()}</Text>
          <Badge fontSize="16px" color="#013964">
            {reward.points} POINTS
          </Badge>
        </HStack>
        <Text px={8} letterSpacing="1px" fontSize="16px" color="brand.100" textAlign="start">{reward.description}</Text>
        <Center mb={8}>
          <Button
            variant="solidSecondary"
            loadingText="Loading..."
            isDisabled={!hasEnoughPoints}
            onClick={hasEnoughPoints ? onClaim : undefined}
            placeSelf="center"
            p="0.5em 3em"
            justifySelf="end"
            mt="2em"
            borderRadius={4}
            color={!hasEnoughPoints ? 'brand.700' : undefined}
            rightIcon={!hasEnoughPoints ? <LockIcon /> : undefined}
          >
            {translate('CLAIM REWARD')}
          </Button>
        </Center>
    </VStack>
  )
}