import React, { useRef, useState, useEffect } from "react";
import { Flex, Stack, useToast, Button, Text, Input, Image, Box } from "@chakra-ui/react";
import { Page } from "../components/Page";
import { authStore } from "../store/AuthStore";
import { useLanguage } from "../lib/LanguageContext";

async function toBlob ( canvas:HTMLCanvasElement, type:string ='image/jpeg', quality: number = 0.45 ):Promise<Blob> {
  return new Promise( ( resolve, reject ) => {
    try {
      canvas.toBlob( ( blob ) => {
        if (!blob) {
          reject('error');
        } else {
          resolve( blob );
        }
      }, type, quality );
    } catch ( e ) {
      reject( e );
    }
  });
}

async function getResizedImageBitmap ( file:File, options:ImageBitmapOptions ):Promise<HTMLCanvasElement> {
  return new Promise ( ( resolve, reject ) => {
    try {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const reader = new FileReader();
      reader.onload = async function( e ) {
        if (!e?.target?.result) {
          reject('error');
        }
        const arrayBuffer = e.target?.result;
        const blobOptions = { type: file.type };
        const blobParts = [arrayBuffer];
        const blob = new Blob( blobParts as BlobPart[], blobOptions );
        const imageBitmap = await createImageBitmap( blob, options ); // according to spec, only supplying resizeWidth will calculate resizeHeight maintaining aspect ratio
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        context?.drawImage(imageBitmap, 0, 0);
        resolve ( canvas );
      };
      reader.readAsArrayBuffer(file);
    } catch ( e ) {
      reject ( e );
    }
  });
}

export const UploadReceipt: React.FC = () => {
  const user = authStore.user;
  useEffect(() => {
    if (!user) {
      const u = authStore.getUser();
      if (u) authStore.reFetchUser(u?.id)
    }
  }, [ user ]);
//  console.log(JSON.parse(JSON.stringify(user)));
  const toast = useToast();
  const [src, setSrc] = useState('');
  const [file, setFile] = useState<File>();
  const [isLoading, setLoading] = useState(false);
  const input = useRef<any>(0);
  const { translate } = useLanguage();

  return (
    <Page>
      <Stack w={["100%", "100%", "500px"]} h="100vh" m={[0, 0, '2em']} direction="column" spacing="2em">
        <Flex
          position="relative"
          alignItems="center"
          w={['100%', '100%', '500px']}
          maxW="100%"
          padding="25px"
          border="1px dashed blue"
          borderRadius="3px"
          transition="0.2s"
          my={1}
        >
          <Button
            flexShrink={0}
            border="1px solid rgba(255, 255, 255, 0.1)"
            borderRadius="3px"
            padding="8px 15px"
            mr="10px"
            fontSize="12px"
            textTransform="uppercase"
            userSelect="none"
            variant="solidSecondary"
          >
            {translate('Choose File')}
          </Button>
          <Text
            fontSize="small"
            fontWeight={300}
            lineHeight={1.4}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            cursor="pointer"
            userSelect="none"
          >
            {translate('or drag and drop the file here')}
          </Text>
          <Input
            type="file"
            position="absolute"
            left={0}
            top={0}
            height="100%"
            width="100%"
            cursor="pointer"
            opacity={0}
            _focus={{
              outline: 0,
            }}
            ref={input}
            accept="image/png, image/jpeg, image/jpg, image/jpeg, image/webp"
            onChange={() => {
              const file = input.current?.files[0];
              if (file) {
                setFile(file);
                const src = URL.createObjectURL(file);
                setSrc(src);
              }
            }}
          />
        </Flex>
        {src ? (
          <>
            <Image
              alt="no-image"
              boxSize="500px"
              src={src}
            />
            <Button
              w="250px"
              variant="solidSecondary"
              alignSelf="center"
              isLoading={isLoading}
              onClick={async () => {
                setLoading(true);
                if (user?.id && file) {
                  const input = 'https://receipt.dxl.stedion.io/api/v1/receipts';
                  const customer_id = user.id;
                  const imageBitmapOptions: ImageBitmapOptions = { resizeWidth: 1200, resizeQuality: 'high' };
                  const canvas = await getResizedImageBitmap( file, imageBitmapOptions );
                  const resizedBlob = await toBlob( canvas );
                  const headers: any = {};
                  const method = 'POST';
                  const duplex = 'half';
                  let body;

                  console.info(`image resized to ${resizedBlob.size} bytes, beginning image transfer with customer ${customer_id}`);

                  headers['Content-Type'] = resizedBlob.type;
                  // headers['Content-Length'] = resizedBlob.size;
                  headers['Transfer-Encoding'] = 'chunked';
                  headers['Authorization'] = `Bearer ${customer_id}`;
                  body = resizedBlob;
                  const init = { method, headers, duplex, body };
                  try {
                    const response = await fetch ( input, init );
                    if ( response.status !== 200 ) {
                      console.warn(`Response not ok with status ${response.status} and payload: ${await response.text()}`);
                      throw new Error('Server Error');
                    }
                    console.info(`Response is ok with status ${response.status}`);
                    toast({
                      status: 'success',
                      title: `Successfully uploaded receipt`,
                      position: 'top',
                    });
                  } catch ( e ) {
                    console.error(e);
                    toast({
                      status: 'error',
                      title: `Server Error`,
                      position: 'top',
                    });
                  }
                } else {
                  toast({
                    status: 'error',
                    title: `Auth error`,
                    position: 'top',
                  });
                }
                setLoading(false);
              }}
              _disabled={{
                bg: 'brand.500 !important',
                color: 'brand.700',
                opacity: 0.5
              }}
            >
              {translate('Upload')}
            </Button>
          </>
        ) : (
          <Box h="500px" />
        )}
      </Stack>
    </Page>
  )
};