import React from "react";
import { Grid, Heading, Link, Text, useMediaQuery } from "@chakra-ui/react";
import { useLanguage } from "../lib/LanguageContext";

export const Footer: React.FC = () => {
  const [isDesktop] = useMediaQuery('(min-width: 800px)');
  const { translate } = useLanguage();

  return (
    <Grid
      gap="16px"
      height="200px"
      backgroundColor="brand.100"
      color="brand.700"
      pt="2em"
      pl="1em"
      pb="3em"
      autoFlow={isDesktop ? 'column' : 'row'}
      placeItems="center"
    >
      {isDesktop && <Heading letterSpacing="-1px" textAlign="center" size="lg">Edmonton Airport</Heading>}
      <Grid className="footer-items">
        <Grid>
          {!isDesktop && <Heading letterSpacing="-1px" textAlign="center" size="lg">Edmonton Airport</Heading>}
          <Link textAlign="center" variant="black" href="#">{translate('Who We Are')}</Link>
          <Link textAlign="center" variant="black" href="#">{translate('Locations')}</Link>
          <Link textAlign="center" variant="black" href="#">{translate('Terms and Conditions')}</Link>
        </Grid>
      </Grid>
      <Grid gap="8px" placeContent="center">
        <Text textAlign="center" fontSize="11px">©Edmonton Airport 2024 </Text>
      </Grid>
    </Grid>
  )
}