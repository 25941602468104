import { Grid, Text, Box, Image, Button, useMediaQuery, useDisclosure, Center, IconButton } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { format } from "date-fns";
import { Challenge } from "../store/ChallengesStore";
import { VideoModal } from "./VideoModal";
import { CheckCircleIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { challengesStore } from "../store/ChallengesStore";
import QuizModal from "./QuizModal";
import { useLanguage } from "../lib/LanguageContext";

type ChallengeCardProps = {
  challenge: Challenge;
  userId?: string;
}

export const ChallengeCard: React.FC<ChallengeCardProps> = ({ challenge, userId }) => {
  const { translate } = useLanguage();
  const urls = challenge?.cf_video_urls;
  const totalCompleted = challenge.progress.criteria.reduce((acc, c) => acc += c.current, 0);
  const totalCriteria = challenge.progress.criteria.reduce((acc, c) => acc += c.threshold, 0);
  const percentage = totalCompleted > 0 ? 100 * totalCompleted / totalCriteria : 0;
  const [isDesktop] = useMediaQuery('(min-width: 800px)');
  const {isOpen, onClose, onOpen} = useDisclosure();
  const {
    isOpen: quizOpen,
    onOpen: onQuizOpen,
    onClose: onQuizClose,
  } = useDisclosure();
  const challengeUrls = useMemo(() => {
    if (urls) {
      return [...urls];
    }
    return [];
  }, [urls]);
  const handlePress = useCallback(() => {
    if (challengeUrls) {
      onOpen();
    }
  }, [challengeUrls, onOpen]);

  return (
    <Grid
      borderRadius="10px"
      position="relative"
      overflow="hidden"
      w={isDesktop ? '450px' : undefined}
      bg="brand.950"
      cursor="pointer"
    >
      <Box mb="1em" position="relative" overflow="hidden" maxH={isDesktop ? '400px' : undefined}>
        <Image w="100%" src={challenge.image.medium} />
        <Text padding="1em" bg="rgba(0,0,0,.5)" position="absolute" right="1em" top="1em" color="white">{totalCompleted} / {totalCriteria}</Text>
        {(challenge.category === 'quizz' && challenge.progress.overall !== 100) && (
          <Center position="absolute" right={6} top={["100px", "100px", "150px"]}>
            <Button
              size="md"
              variant="solid"
              fontSize="18px"
              color="white"
              borderRadius={2}
              onClick={onQuizOpen}
            >
              {translate('Start')}
            </Button>
          </Center>
        )}
        {challenge.category === 'social' && (
          <Center position="absolute" right={6} top={["100px", "100px", "150px"]}>
            <Button
              size="md"
              variant="solid"
              fontSize="18px"
              borderRadius={2}
              color="white"
              onClick={() => {
                if (userId) {
                  window.open(challenge.url, '_blank');
                  challengesStore.postVisit(userId);
                }
              }}
            >
              {translate('Follow us')}
            </Button>
          </Center>
        )}
        {challengeUrls?.length > 0 && (
          <Center
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
          >
            <IconButton
              size="lg"
              variant="solid"
              aria-label="PlayVideo"
              fontSize="40px"
              backgroundColor="brand.video"
              _hover={{ backgroundColor: 'brand.red' }}
              borderRadius={6}
              width={'75px'}
              icon={
                <TriangleUpIcon
                  transform={'rotate(90deg)'}
                  color={'white'}
                  w={7}
                  h={7}
                />
              }
              onClick={onOpen}
            />
          </Center>
        )}
        <QuizModal
          userId={userId ?? ''}
          quizId={challenge.cf_flash_quiz_id ?? ''}
          quizIds={challenge.cf_quiz_ids}
          isOpen={quizOpen}
          onClose={() => {
            // refetch();
            onQuizClose();
          }}
        />
      </Box>
      <Grid py="1em">
        {challenge.ends_at && <Text fontStyle="italic" alignSelf="end" color="brand.100">Until {format(new Date(challenge.ends_at), 'MMM eo')}</Text>}
        <Grid pt="1em" px="1em" templateColumns="auto auto" gap="0.5em">
          <Text textAlign="start" fontWeight="bold" color="white" fontSize="16px" noOfLines={1} textOverflow="ellipsis">{challenge.title.toUpperCase()}</Text>
          <Text textAlign="end" fontWeight="bold" color="white" fontSize="16px">+{challenge.points} POINTS</Text>
        </Grid>
        <Text pl="1em" py="2em" color="white" textAlign="start">{challenge.description}</Text>
      </Grid>
      {challenge.cta_label
        && <Button
            rightIcon={percentage === 100 ? <CheckCircleIcon textAlign="end" fontSize="1em" color="white" /> : undefined}
            onClick={percentage !== 100 ? handlePress : undefined}
            mt="1em"
            p="0.6em 3em"
            placeSelf="end"
            fontSize="14px"
            variant="solidSecondary">
              {percentage !== 100 ? challenge.cta_label.toUpperCase() : 'COMPLETED'}
            </Button>
      }
      {challengeUrls?.length && <VideoModal isOpen={isOpen} onClose={onClose} urls={challengeUrls} />}
    </Grid>
  )
}