import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { authStore } from "../store/AuthStore";
import { rewardsStore } from "../store/RewardsStore";
import { Box, VStack, Grid, Heading, Skeleton, Text, useMediaQuery, useToast } from "@chakra-ui/react";
import { RewardItem } from "../components/RewardItem";
import { Page } from "../components/Page";
import { useLanguage } from "../lib/LanguageContext";

const FillerBox = () => (
  <Box
    w="350px"
    h="400px"
    backgroundColor="white"
    borderRadius={8}
    m="0 1em"
  >
    <Skeleton height="160px" />
    <Skeleton height="20px" my={4} />
    <Skeleton height="20px" mb={4} />
    <Skeleton height="20px" />
  </Box>
);

export const Rewards: React.FC = observer(() => {
  const user = authStore.user;
  const { lang, translate, translateOnTheSpot } = useLanguage();
  const toast = useToast();
  useEffect(() => {
    if (user?.id) {
      rewardsStore.getRewards(user.id, lang);
      authStore.reFetchUser(user.id);
    } else {
      authStore.getUser();
    }
  }, [user?.id, lang])
  const rewards = rewardsStore.rewards;
  const [isDesktop] = useMediaQuery('(min-width: 800px)');

  return (
    <Page>
      <VStack>
        <Text mt={4} fontSize="20px" color="brand.500" textAlign="center">{translateOnTheSpot('You have', 'Vous avez')} <b>{user?.spendable} points</b> {translateOnTheSpot('to Spend', 'à dépenser')}</Text>
        <Heading my={4} color="brand.500" letterSpacing="-1px" size="xl" borderBottom="1px solid" borderColor="brand.300">{translate('REWARDS')}</Heading>
        <Grid templateColumns={isDesktop ? 'auto auto auto' : '1fr'} py="4em" gap="2em" w="100%" backgroundColor="#013964">
          { rewardsStore.isLoading && 
            <>
              <FillerBox />
              <FillerBox />
              <FillerBox />
            </>
          }
          {
            !rewardsStore.isLoading && rewards.map((r) => {
              const hasEnoughPoints = r.points <= (user?.spendable || 0);
              return (
                <RewardItem
                  key={r.id}
                  reward={r}
                  hasEnoughPoints={hasEnoughPoints}
                  spendable={user?.spendable || 0}
                  onClaim={async () => {
                    if (user?.id) {
                      const claimResponse = await rewardsStore.claimReward(user.id, r.id);
                      await authStore.reFetchUser(user.id);
                      if (claimResponse.error) {
                        toast({ title: claimResponse.error.message, status: 'error' })
                      } else { 
                        toast({ status: 'success', title: `Successfully spent ${claimResponse.spent} points!` });
                      }
                    }
                  }}
                />
              )
            })
          }
        </Grid>
      </VStack>
    </Page>
  )
})