import { createContext, FC, PropsWithChildren, useState, useContext, useCallback } from "react";
import { CustomDictionary } from "./CustomDictionary";

const language = navigator.language;
const selectedLanguage = language.match(/fr/ig)?.length ? 'fr' : 'en';
const storedLanguage = window.localStorage.getItem('yeg-lang') ?? selectedLanguage;

interface LanguageContextProps {
  lang: string;
  setLang: (lang: string) => void;
  translate: (word: string) => string;
  translateOnTheSpot: (word: string, translation: string) => string;
}

const LanguageContextBase =
  createContext<LanguageContextProps>({
    lang: selectedLanguage,
    setLang: (_: string) => {},
    translate: (_: string) => _,
    translateOnTheSpot: (_: string, __: string) => _
  });

export const useLanguage = () =>
  useContext(LanguageContextBase);

export const LanguageContext: FC<PropsWithChildren> = (props) => {
  const [lang, setLang] = useState<string>(storedLanguage);
  const translate = useCallback((word: string) => {
    if (lang === 'en') {
      return word;
    }
    return CustomDictionary?.[word] ?? word;
  }, [lang]);
  const translateOnTheSpot = useCallback((word: string, translation: string) => {
    if (lang === 'en') {
      return word;
    }
    return translation;
  }, [lang]);

  return (
    <LanguageContextBase.Provider value={{
        lang,
        setLang: (lang: string) => {
          setLang(lang);
          window.localStorage.setItem('yeg-lang', lang);
        },
        translate,
        translateOnTheSpot
      }}>
      {props.children}
    </LanguageContextBase.Provider>
  );
};
