import { Box, Grid, Heading, Skeleton, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { authStore } from "../store/AuthStore";
import { observer } from "mobx-react-lite";
import { challengesStore } from "../store/ChallengesStore";
import { ChallengeCard } from "./ChallengeCard";
import { useLanguage } from "../lib/LanguageContext";

const FillerBox = () => (
  <Box
    w="498px"
    h="450px"
    backgroundColor="white"
    borderRadius={8}
  >
    <Skeleton height="200px" />
    <Skeleton height="20px" my={4} />
    <Skeleton height="20px" mb={4} />
    <Skeleton height="20px" />
  </Box>
);

export const HomeExtraPoints: React.FC = observer(() => {
  const user = authStore.user;
  const { lang, translate } = useLanguage();
  useEffect(() => {
    (async () => {
      if (user?.id) {
        await challengesStore.getChallenges(user.id, lang);
      } else {
        authStore.getUser();
      }
    })();
  }, [user, lang])
  const challenges = challengesStore.challenges;
  const [isDesktop] = useMediaQuery('(min-width: 800px)');

  return (
    <Grid gap="1em" templateRows="auto 1fr" backgroundColor="white800" padding="2em 0 2em 0">
      <Grid py="1em" justifyItems="center" color="brand.100">
        <Heading letterSpacing="-1px" size="sm">{translate('UNLOCK EXCLUSIVE PERKS')}</Heading>
        <Heading letterSpacing="-1px" size="xl" borderBottom="1px solid" borderColor="brand.300">{translate('LEVEL UP')}</Heading>
      </Grid>
      <Grid
          overflow="auto"
          autoFlow={isDesktop ? 'column' : 'row'}
          gap="1em"
          pl="1em"
          pr="1em"
          pb="2em"
          style={{
            scrollbarWidth: 'thin',
          }}
        >
          {challengesStore.isLoading && (
            <>
             <FillerBox />
             <FillerBox />
             <FillerBox />
            </>
          )}
          {!challengesStore.isLoading && (
            challenges.slice().reverse().map((ch) => {
              return (
                <ChallengeCard key={ch.id} challenge={ch} userId={user?.id} />
              )
            })
          )}
      </Grid>
    </Grid>
  )
});