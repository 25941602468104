import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import {
  RouterProvider,
} from "react-router-dom";
import './App.css'
import { router } from './Router';
import { theme } from './chakra-theme';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { LanguageContext } from './lib/LanguageContext';

function App() {
  const [queryClient] = React.useState(
    () =>
        new QueryClient({
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                },
            },
            queryCache: new QueryCache({
                //INFO: Global fetch error toast message handling
                onError: (error, query) => {
                    if (!query?.meta?.disableGlobalErrorNotification) {
                        // window.alert(`${error} API Error`);
                    }
                },
            }),
            mutationCache: new MutationCache({
                onError: (error, context, variables, mutation) => {
                    console.log(error);
                    if (!mutation?.meta?.disableGlobalErrorNotification) {
                        // window.alert(`${error} API Error`);
                    }
                },
            }),
        })
  );

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <LanguageContext>
          <RouterProvider router={router} />
        </LanguageContext>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
