export const CustomDictionary: any = {
  'Home': 'Accueil',
  'Activity History': 'Historique d\'activité',
  'Redeem Rewards': 'Échanger des récompenses',
  'Upload Receipt': 'Télécharger un reçu',
  'Log Out': 'Se déconnecter',
  'WELCOME BACK': 'BIENVENU(E)',
  'Part of the Community since': 'Fait partie de la Communauté depuis',
  'January': 'Janvier',
  'February': 'Février',
  'March': 'Mars',
  'April': 'Avril',
  'May': 'Mai',
  'June': 'Juin',
  'July': 'Juillet',
  'August': 'Août',
  'September': 'Septembre',
  'October': 'Octobre',
  'November': 'Novembre',
  'December': 'Décembre',
  'Unlocked': 'Débloqué',
  'Points to Unlock': 'Points à Débloquer',
  'View My Activity History': 'Voir mon journal d\'activité',
  'UNLOCK EXCLUSIVE PERKS': 'DÉBLOQUEZ DES AVANTAGES EXCLUSIFS',
  'LEVEL UP': 'MONTE DE NIVEAU',
  'Start': 'Commencer',
  'Follow us': 'Suivez-nous',
  'Who We Are': 'À propos',
  'Locations': 'Emplacements',
  'Terms and Conditions': 'Conditions générales d\'utilisation',
  'Flash Quiz': 'Quiz Rapide',
  "Welcome to the quiz! 🎉 We're excited to have you here. Put your knowledge to the test and see how well you do. Remember, the first 100 people to finish will receive a special reward. Good luck and have fun!": 'Bienvenue au quiz ! 🎉 Nous sommes ravis de vous accueillir. Mettez vos connaissances à l’épreuve et voyez ce que vous valez. N’oubliez pas, les 100 premières personnes à terminer recevront une récompense spéciale. Bonne chance et amusez-vous bien !',
  'START': 'COMMENCER',
  'COMPLETE': 'COMPLÉTER',
  'Prev': 'Précédent',
  'Next': 'Suivant',
  'Finish': 'Finir',
  'Activity': 'Activité',
  'Loyalty': 'Loyauté',
  'Spent Points': 'Points Dépensés',
  'Challenge': 'Défi',
  'Visit': 'Visite',
  'Point add': 'Point gagné',
  'Tier up': 'Montée de niveau',
  'Opt In': 'A rejoint',
  'Choose File': 'Sélectionnez un fichier',
  'or drag and drop the file here': 'ou déposez-le ici',
  'Upload': 'Envoyer',
  "CONGRATULATIONS, PUZZLE MASTER! 🎯 You're one of the first 100 members to crack the code on this challenge. Your quick wit and sharp mind just earned you bonus points! Keep flexing those mental muscles - more thrilling challenges await.": "FÉLICITATIONS, MAÎTRE DU PUZZLE ! 🎯 Vous êtes l’un des 100 premiers membres à avoir résolu ce défi. Votre esprit vif et votre intelligence aiguisée vous ont valu des points bonus ! Continuez à exercer votre cerveau, des défis encore plus passionnants vous attendent.",
  "GREAT EFFORT, PUZZLE PRO! 🧩 While you mastered this challenge, the bonus points have already been claimed. But don't let that stop you - your sharp mind is exactly what we need for future quizzes. Stay ready for the next challenge!": "EXCELLENT EFFORT, PRO DU PUZZLE ! 🧩 Vous avez maîtrisé ce défi, mais les points bonus ont déjà été réclamés. Mais ne vous laissez pas décourager, votre esprit vif est exactement ce dont nous avons besoin pour les prochains quiz. Soyez prêt pour le prochain défi !",
  "PUZZLE MASTER IN TRAINING! 🧩 While this challenge eluded you this time, we know you're building skills for the next brain-teaser. Keep that problem-solving spirit alive - more exciting quizzes are coming soon with fresh chances to earn points!": "MAÎTRE DU PUZZLE EN FORMATION ! 🧩 Bien que ce défi vous ait échappé cette fois-ci, nous savons que vous êtes en train de développer les compétences nécessaires pour le prochain casse-tête. Gardez cet esprit de résolution de problèmes bien vivant - des quiz plus passionnants arrivent bientôt avec de nouvelles chances de gagner des points !",
  "This quiz was already answered by the customer correctly": "Ce quiz a déjà été correctement répondu par le client.",
  'REWARDS': 'RÉCOMPENSES',
  "WHAT YOU'LL GET": 'CE QUE VOUS GAGNEREZ',
  'CLAIM REWARD': 'RÉCLAMER RÉCOMPENSE',
};
