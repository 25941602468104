import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import StyledRadioButtons from './StyledRadioButtons';
import MessageModal from './MessageModal';
import { useGetQuiz } from '../lib/queries';
import { challengesStore } from '../store/ChallengesStore';
import { useLanguage } from '../lib/LanguageContext';

const START_QUIZ =
  "Welcome to the quiz! 🎉 We're excited to have you here. Put your knowledge to the test and see how well you do. Remember, the first 100 people to finish will receive a special reward. Good luck and have fun!";

const messages = {
  puzzleMaster: "CONGRATULATIONS, PUZZLE MASTER! 🎯 You're one of the first 100 members to crack the code on this challenge. Your quick wit and sharp mind just earned you bonus points! Keep flexing those mental muscles - more thrilling challenges await.",
  puzzlePro: "GREAT EFFORT, PUZZLE PRO! 🧩 While you mastered this challenge, the bonus points have already been claimed. But don't let that stop you - your sharp mind is exactly what we need for future quizzes. Stay ready for the next challenge!",
  puzzleRocky: "PUZZLE MASTER IN TRAINING! 🧩 While this challenge eluded you this time, we know you're building skills for the next brain-teaser. Keep that problem-solving spirit alive - more exciting quizzes are coming soon with fresh chances to earn points!",
  alreadyDone: "This quiz was already answered by the customer correctly",
};

interface QuizModalProps {
  userId: string;
  quizId: string;
  quizIds: string[];
  isOpen: boolean;
  onClose: () => void;
};

const QuizModal = ({ userId, quizId, quizIds, isOpen, onClose }: QuizModalProps) => {
  const toast = useToast();
  const { lang, translate } = useLanguage();
  const [answers, setAnswers] = useState<any[]>([]);
  const [message, setMessage] = useState('');
  const [isLoading, onLoading] = useState(false);
  const { data: quiz, isSuccess } = useGetQuiz(userId, quizIds, lang);

  const [totalSteps, setTotalSteps] = useState((quiz as any)?.questions?.length + 1);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');

  useEffect(() => {
    setTotalSteps((quiz as any)?.questions?.length + 1);
  }, [quiz, isSuccess]);

  const goToNextStep = async () => {
    if (currentStep < totalSteps) {
      if (selectedAnswer !== undefined) {
        try {
          onLoading(true);
          const res = await challengesStore.validateQuizQuestion(
            userId,
            `${(quiz as any)?.questions[currentStep - 1].id ?? ''}`,
            selectedAnswer
          );
          const { correct, completed, alreadyDone } = (res as any);
          answers[currentStep - 1] = {
            correct, completed, alreadyDone
          };
          setAnswers(answers);
        } catch (error) {
          console.log('there was an error', error);
        }
        setSelectedAnswer('');
        setCurrentStep(currentStep + 1);
        onLoading(false);
      } else {
        toast({
          title: 'Please select an answer',
          status: 'info',
          duration: 9000,
          isClosable: true,
        });
      }
    }

    if (currentStep === (quiz as any)?.questions?.length) {
      challengesStore.checkChallengeStatus(userId, quizId ).then(res => {
        const { first_hundred } = (res as any);
        if (first_hundred) {
          if (answers?.every(({alreadyDone}) => alreadyDone)) {
            setMessage(messages.alreadyDone);
          } else {
            setMessage(messages.puzzleMaster);
          }
        } else if (answers?.some(({correct}) => !correct)) {
          setMessage(messages.puzzleRocky);
        } else if (!first_hundred) {
          setMessage(messages.puzzlePro);
        }
        setAnswers([]);
      });
    }
  };

  const goToPrevStep = () => {
    if (currentStep > 1) {
      setSelectedAnswer('');
      setCurrentStep(currentStep - 1);
    }
  };

  const startQuiz = () => {
    setCurrentStep(currentStep + 1);
  };

  const completeQuiz = () => {
    onClose();
    setCurrentStep(0);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={['md', 'lg']}
    >
      <ModalOverlay />
      <ModalContent overflow={'auto'}>
        <ModalHeader mt={4}>
          <Text
            justifySelf={'center'}
            fontWeight="bold"
            fontSize={'xx-large'}
            color="teal.900"
          >
            Yeg {translate('Flash Quiz')}
          </Text>
          <ModalCloseButton
            borderRadius="full"
            color="#045CA4"
            fontSize="sm"
            mt={3}
            _hover={{
              bg: 'brand.green',
              borderColor: 'red.600',
            }}
          />
        </ModalHeader>
        {currentStep === 0 || currentStep === totalSteps ? (
          <MessageModal
            displayText={currentStep === 0 ? translate(START_QUIZ) : translate(message)}
            onClickHandler={
              currentStep === 0 ? startQuiz : completeQuiz
            }
            buttonText={currentStep === 0 ? translate('START') : translate('COMPLETE')}
          />
        ) : (
          <>
            <ModalBody
              minHeight={[
                '300px',
                '300px',
                '300px',
                '300px',
                '300px',
              ]}
              minWidth={[
                '400px',
                '400px',
                '400px',
                '450px',
                '450px',
              ]}
            >
              {/* custom progress bar */}
              <Grid
                templateColumns={`repeat(${
                  totalSteps - 1
                }, 1fr)`}
                gap={'2px'}
                mb={6}
              >
                {Array.from(
                  { length: totalSteps - 1 },
                  (_, index) => (
                    <GridItem
                      key={index}
                      h="15px"
                      bg={
                        index < currentStep
                          ? '#045CA4'
                          : '#A8B8BC'
                      }
                    />
                  )
                )}
              </Grid>
              <Text ml={2} fontWeight={500} fontSize="lg" mb={4}>
                {currentStep}.{' '}
                {(quiz as any)?.questions?.[currentStep - 1]?.title}
              </Text>
              <StyledRadioButtons
                options={
                  (quiz as any)?.questions?.[currentStep - 1]?.answers
                }
                onChange={(answer: any) => {
                  setSelectedAnswer((quiz as any)?.questions?.[currentStep - 1]?.answers?.indexOf(answer));
                }}
              ></StyledRadioButtons>
            </ModalBody>
            <ModalFooter justifyContent={'flex-end'}>
              <Button
                isDisabled={currentStep === 1}
                _disabled={{
                  opacity: 0.5,
                  cursor: 'not-allowed',
                  bg: 'brand.red',
                }}
                color="white"
                _hover={{
                  transform: 'scale(1.05)',
                  transition: 'all 0.3s ease',
                }}
                onClick={goToPrevStep}
              >
                {translate('Prev')}
              </Button>
              <Button
                ml={5}
                onClick={goToNextStep}
                color="white"
                _hover={{
                  transform: 'scale(1.05)',
                  transition: 'all 0.3s ease',
                }}
                isLoading={isLoading}
              >
                {currentStep === totalSteps - 1
                  ? translate('Finish')
                  : translate('Next')}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default QuizModal;
