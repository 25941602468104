import { useMutation, useQuery } from '@tanstack/react-query';
import { challengesStore } from '../store/ChallengesStore';

export const useValidateQuizQuestion = (options?: any) =>
  useMutation({
    mutationKey: ['validate-quiz-question'],
    mutationFn: async ({userId, quizId, quizAnswer}: {userId: string, quizId: string, quizAnswer: string}) => {
      return challengesStore.validateQuizQuestion(userId, quizId, quizAnswer);
    },
    ...options,
  });

export const useGetQuiz = (userId: string, quizQuestions: string[], acceptLang?: string, options?: any) =>
  useQuery({
    queryKey: [`${userId}-quiz`],
    queryFn: async () => {
      const res = await challengesStore.fetchQuiz(userId, quizQuestions, acceptLang);
      try {
        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }
    
        const json = await res.json();
        return json;
      } catch (error) {
        console.log(error);
      }
    },
    enabled: !!quizQuestions?.length,
    ...options,
  });

export const usePostReceipt = (options: any) =>
  useMutation({
    mutationFn: async (userId: string, image: HTMLInputElement) => {
      if (image?.files) {
        const f = image.files[0];
        const stream = f.stream();
        const { type, size } = f;
        const headers = {
          'Content-Length': `${size}`,
          'Content-Type': type,
          'Authorization': `Bearer ${userId}`,
        };
        const body = stream;
        const method = 'POST';
        const init = {
          body,
          method,
          headers
        };
        return fetch('https://dxl.stedion.io/api/v1/receipts', init);
      }
      // eslint-disable-next-line no-throw-literal
      throw "There was an error while uploading your receipt";
    },
    ...options,
  });

// eslint-disable-next-line
export default {
  usePostReceipt,
};

export const usePostVisitEvent = (options: any) => useMutation({
  mutationKey: 'visit-event',
  mutationFn: async ({ userId }: { userId: string}) => {
    const headers = {
      'Authorization': `Bearer ${userId}`,
    };
    const body = JSON.stringify({
      "customer": userId,
      "action": "visit",
      "data": {
        "url": "https://facebook.com"
      }
    });
    const method = 'POST';
    const init = {
      body,
      method,
      headers
    };
    return fetch('https://dxl.stedion.io/api/v1/receipts', init);
  },
  onError: (err) => {
    console.error(err);
  },
  ...options,
  enabled: true,
});
