import { makeAutoObservable } from 'mobx';
import { AntavoImage } from './shared/Image';

type ChallengeProgressCriteria = {
  action: string;
  message: string;
  threshold: number;
  current: number;
  is_completed: boolean;
}

type ChallengeProgress = {
  overall: number;
  criteria: ChallengeProgressCriteria[];
}

export interface Challenge {
  id: string;
  title: string;
  description: string;
  image: AntavoImage;
  points: number;
  progress: ChallengeProgress;
  url: string;
  cta_label: string;
  completions: number;
  ends_at: string;
  starts_at: string;
  cf_video_urls: string[];
  category: string;
  cf_flash_quiz_id: string;
  cf_quiz_ids: string[];
}

class ChallengesStore {
  challenges: Challenge[] = [];
  isLoading: boolean = false;
  rewardApiUrl?: string = process.env.REACT_APP_REWARD_API_URL;
  customerUrl?: string = process.env.REACT_APP_CUSTOMER_API_URL;

  constructor() {
    makeAutoObservable(this);
  }

  async getChallenges(customerId: string, acceptLang?: string) {
    this.isLoading = true;
    if (!this.rewardApiUrl) {
      console.error('process.env.REACT_APP_REWARD_API_URL is not defined and it is needed by the challenge store');
      return;
    }
    const res = await fetch(`${this.rewardApiUrl}/${customerId}/challenges`,  { headers: { 'Accept-Language': acceptLang ?? 'en' }});
    const json = await res.json();
    if (json.data) {
      this.challenges = json.data;
    }
    this.isLoading = false;
    return json.data;
  }

  async getAllEntityChallenges(acceptLang?: string) {
    this.isLoading = true;
    const res = await fetch(
      'https://api.salesdemo.antavo.com/entities/challenges/challenge?api_key=AN2OF2LPTAAFGDVOCOH',
      { headers: { 'Accept-Language': acceptLang ?? 'en' }}
    )
    if (res.ok) {
      const json = await res.json();
      return json.data
    }
  }

  async watchVideo(customerId: string, url: string, acceptLang?: string) {
    this.isLoading = true;
    if (!this.customerUrl) {
      console.error('process.env.REACT_APP_AUTH_API_URL is not defined and it is needed by the auth store');
      return;
    }
    const res = await fetch(
      `${this.customerUrl}customer/event`,
      {
        headers: {
          'Accept-Language': acceptLang ?? 'en'
        },
        method: 'POST',
        body: JSON.stringify({
          customer: customerId,
          action: 'video',
          data: {
            url
          }
        })
      }
    );
    const json = await res.json();
    this.isLoading = false;
    return json;
  }

  async fetchQuiz(userId: string, quizQuestions: string[], acceptLang?: string) {
    const response = await fetch(
      `${this.customerUrl}/${userId}/quiz_questions?quizId=${quizQuestions}`,
      { headers: { 'Accept-Language': acceptLang ?? 'en' }}
    );
    return response;
  }

  async validateQuizQuestion (userId: string, quizId: string, quizAnswer: string) {
    const response = await fetch(`${this.customerUrl}/${userId}/quiz_answer`, {
      method: 'POST',
      body: JSON.stringify({
        "quizId": quizId,
        "answer": quizAnswer,
      }),
      headers: {
        "Content-Type": "application/json",
        'Accept-Language': 'en',
        'Authorization': `Bearer ${userId}`,
      }
    });
    return response;
  };

  async checkChallengeStatus  (userId: string, quizId: string) {
    const response = await fetch(`${this.customerUrl}/${userId}/check_challenge_status`, {
      method: 'POST',
      body: JSON.stringify({
        "challenge_id": quizId,
      }),
      headers: {
        "Content-Type": "application/json",
        'Accept-Language': 'en',
        'Authorization': `Bearer ${userId}`,
      }
    });
    return await response.json();
  }

  async postVisit(userId: string) {
    const res =  await fetch(`${this.customerUrl}customer/event`,
      {
        method: 'POST',
        body: JSON.stringify({
          "customer": userId,
          "action": "visit",
          "data": {
            "url": "https://facebook.com"
          }
        }),
        headers: {
          "Content-Type": "application/json",
          'Accept-Language': 'en',
          'Authorization': `Bearer ${userId}`,
        }
      }
    );
    return res;
  }

  async postReceipt(userId: string, f?: File) {
    if (!f) {
      // eslint-disable-next-line
      throw('error while uploading file');
    }
    const stream = f.stream();
    const { type, size } = f;
    const headers = {
      'Content-Length': `${size}`,
      'Content-Type': type,
      'Authorization': `Bearer ${userId}`,
    };
    const body = stream;
    const method = 'POST';
    const init = {
      body,
      method,
      headers,
      duplex: "half",
    };
    return fetch('https://receipt.dxl.stedion.io/api/v1/receipts', init);
  }

}

export const challengesStore = new ChallengesStore();