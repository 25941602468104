import { Button, ModalBody, ModalFooter, Text } from '@chakra-ui/react';

interface MessageModalProps {
  onClickHandler: () => void;
  displayText: string;
  buttonText: string;
}

const MessageModal = ({ onClickHandler, displayText, buttonText }: MessageModalProps) => {
  return (
    <>
      <ModalBody
        minHeight={['300px', '300px', '300px', '300px', '300px']}
        minWidth={['400px', '400px', '400px', '450px', '450px']}
        justifyContent={'center'}
        alignContent={'center'}
      >
        <Text
          fontWeight={500}
          textAlign={'center'}
          mx={10}
          fontSize={'large'}
        >
          {displayText}
        </Text>
      </ModalBody>
      <ModalFooter justifyContent={'center'}>
        <Button
          _hover={{
            transform: 'scale(1.05)',
            transition: 'all 0.3s ease',
          }}
          color="white"
          fontSize={'large'}
          size={'lg'}
          ml={5}
          onClick={onClickHandler}
        >
          {buttonText}
        </Button>
      </ModalFooter>
    </>
  );
};

export default MessageModal;
